<template>
    <div>
        <v-layout row wrap style="padding: 10px;">
            <v-flex xs6>
                Key
            </v-flex>
            <v-flex xs6>
                Value
            </v-flex>
        </v-layout>
        <div v-for="(e, i) in entries" v-bind:key=i>
            <v-layout row wrap>
                <v-flex xs6 style="padding: 0 12px">
                    <v-text-field
                      v-model="e[0]"
                      :rules="[() => !isDuplicated(e[0]) || 'This key is duplicated']"
                      style="padding: 0"/>
                </v-flex>
                <v-flex xs6 style="padding: 0 12px">
                    <v-text-field
                    v-model="e[1]"
                    style="padding: 0"/>
                </v-flex>
            </v-layout>
        </div>
        <v-btn color="green" @click="add">Add new pair</v-btn>
    </div>
</template>

<script>
export default {
  name: 'MetaEdit',
  props: {
    value: Object,
  },
  data() {
    return {
      entries: [],
    };
  },
  created() {
    this.entries = Object.entries(this.value);
  },
  watch: {
    entries: {
      deep: true,
      handler(newValue) {
        this.$emit('input', Object.fromEntries(this.cleanEntries(newValue)));
      },
    },
  },
  methods: {
    cleanEntries(entries) {
      const seen = {};

      return entries.filter((entry) => {
        if (entry[0].trim() === '') {
          return false;
        }

        const b = Object.prototype.hasOwnProperty.call(seen, entry[0]) ? false : (seen[entry[0]] = true);
        return !!b;
      });
    },
    isDuplicated(key) {
      let count = 0;
      for (let i = 0; i < this.entries.length; i += 1) {
        if (this.entries[i][0].trim() !== '') {
          if (this.entries[i][0] === key) {
            count += 1;
          }
          if (count === 2) {
            return true;
          }
        }
      }
      return false;
    },
    add() {
      this.entries.push(['', '']);
    },
  },
};
</script>
